
import {defineComponent} from "vue";

export default defineComponent({
  name: "KtWidget6",
  components: {},
  props: {
    widgetClasses: {type: String, default: ""},
    color: {type: String, default: ""},
    description: {type: String, default: ""},
    progress: {type: String, default: ""},
    title: {type: String, default: ""},
  },
});
