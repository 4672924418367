
import { defineComponent } from "vue";

export default defineComponent({
  name: "KtWidget2",
  components: {},
  props: {
    widgetClasses: {type: String, default: ""},
    title: {type: String, default: ""},
    description: {type: String, default: ""},
    avatar: {type: String, default: ""},
  },
});
