
import { defineComponent } from "vue";

export default defineComponent({
  name: "KtWidget5",
  components: {},
  props: {
    widgetClasses: {type: String, default: ""},
    color: {type: String, default: ""},
    iconColor: {type: String, default: ""},
    svgIcon: {type: String, default: ""},
    title: {type: String, default: ""},
    description: {type: String, default: ""},
  },
});
