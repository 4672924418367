
import { defineComponent } from "vue";

export default defineComponent({
  name: "KtWidget1",
  components: {},
  props: {
    widgetClasses: {type: String, default: ""},
    background: {type: String, default: ""},
    title: {type: String, default: ""},
    time: {type: String, default: ""},
    description: {type: String, default: ""},
  },
});
